//
// Plugins: Miscellaneous
// Old plugin codes
//

// _fix for sparkline tooltip
.jqstooltip {
  height: auto !important;
  padding: 5px !important;
  width: auto !important;
}

// jQueryUI
.connectedSortable {
  min-height: 100px;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sort-highlight {
  background: $gray-100;
  border: 1px dashed $gray-300;
  margin-bottom: 10px;
}

// Charts
.chart {
  overflow: hidden;
  position: relative;
}


.dark-mode {
  .irs--flat .irs-line {
    background-color: lighten($dark, 10%);
  }
  .jsgrid-edit-row > .jsgrid-cell,
  .jsgrid-filter-row > .jsgrid-cell,
  .jsgrid-grid-body, .jsgrid-grid-header,
  .jsgrid-header-row > .jsgrid-header-cell,
  .jsgrid-insert-row > .jsgrid-cell,
  .jsgrid-row > .jsgrid-cell,
  .jsgrid-alt-row > .jsgrid-cell {
    border-color: $gray-600;
  }
  .jsgrid-header-row > .jsgrid-header-cell,
  .jsgrid-row > .jsgrid-cell {
    background-color: $dark;
  }
  .jsgrid-alt-row > .jsgrid-cell {
    background-color: lighten($dark, 2.5%);
  }
  .jsgrid-selected-row > .jsgrid-cell {
    background-color: lighten($dark, 5%);
  }
}
